const isDev = () => {
  return window.location.hostname === 'localhost'
}

const useDevApi = () => {
  return process.env.REACT_APP_DEV_API === 1
}

const generateRandomInteger = (min, max) => {
  return Math.floor(Math.random() * max) + min
}

// JSON Dumping
const jsonToString = json => {
  return JSON.stringify(json, null, 2)
}

const dumpJson = (label, obj) => {
  console.log(label + ': ' + jsonToString(obj))
}

// 2D Array (Grid) Dumping
const gridToString = (grid, options = { header: true, stringify: true }) => {
  let result = ''
  const header = []

  if (typeof grid === 'object' && grid.length > 0) {
    for (let i = 0; i < grid[0].length; i++) {
      header.push(i.toString())
    }

    if (options.header) {
      if (options.stringify) {
        result += JSON.stringify(header) + '\n'
      } else {
        for (let i = 0; i < header.length; i++) {
          result += header[i]
        }
        result += '\n'
      }
    }

    for (let i = 0; i < grid.length; i++) {
      const row = grid[i]
      if (options.stringify) {
        result += JSON.stringify(row) + '\n'
      } else {
        for (let col = 0; col < row.length; col++) {
          switch (row[col]) {
            // Ignore debug tokens used in creation of the random maze
            case 'N':
            case 'S':
            case 'E':
            case 'W':
            case '-':
              result += '.'
              break
            default:
              result += row[col]
          }
        }
        result += '\n'
      }
    }
  }

  return result
}

const dumpGrid = (label, obj) => {
  console.log(`${label}\n` + gridToString(obj))
}

const shuffle = arr => {
  const newArr = [...arr] // Don't mutate existing array
  for (let i = newArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = newArr[i]
    newArr[i] = newArr[j]
    newArr[j] = temp
  }
  return newArr
}

export {
  isDev,
  useDevApi,
  dumpJson,
  dumpGrid,
  generateRandomInteger,
  gridToString,
  jsonToString,
  shuffle,
}
