const APP_TITLE = 'Pacman Imitation Learning Tool'
const APP_VERSION = '2023.12.04-1'

const SITE_TITLE_POSTFIX = `| ${APP_TITLE}`

const MAX_RELOADS = 5

const SPACE_TOKEN = '.'

const WALL_TOKEN = 'X'
const PELLET_TOKEN = 'O'
const PACMAN_TOKEN = 'P'
const GHOST_TOKEN = 'G'

const EAST = 'E'
const WEST = 'W'
const NORTH = 'N'
const SOUTH = 'S'

const MAX_GHOSTS = 2
const MAX_PACMEN = 1
const MAX_PELLETS = 3
const MAX_BRIDGE_REMOVALS = 4

const MAZE_HEIGHT = 9 // odd number required
const MAZE_WIDTH = 11 // odd number required

export {
  APP_TITLE,
  APP_VERSION,
  SITE_TITLE_POSTFIX,
  MAX_RELOADS,
  // TOKENS
  SPACE_TOKEN,
  WALL_TOKEN,
  PELLET_TOKEN,
  PACMAN_TOKEN,
  GHOST_TOKEN,
  // Directions
  EAST,
  WEST,
  NORTH,
  SOUTH,
  // Maze Options
  MAX_GHOSTS,
  MAX_PACMEN,
  MAX_PELLETS,
  MAX_BRIDGE_REMOVALS,
  MAZE_HEIGHT,
  MAZE_WIDTH,
}
