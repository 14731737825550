import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'
import { isEmpty } from 'ramda'

import { Helmet } from 'react-helmet-async'

import { makeStyles } from '@material-ui/core/styles'

import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import BlockMazeDisplay from '../../mazes/views/BlockMazeDisplay'

import {
  // dumpGrid, dumpJson,
  generateRandomInteger,
  shuffle,
} from '../../common'
import { SITE_TITLE_POSTFIX, WALL_TOKEN, SPACE_TOKEN, PELLET_TOKEN } from '../../constants'
import { mazeStringToArray, mazeArrayToString } from '../../mazes/mazeCommon'

import { patchUserLobby } from '../../api'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
    },

    statsBar: {
      marginTop: theme.spacing(1),
    },

    workspace: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      marginTop: theme.spacing(3),
    },

    // Workspace Top Section
    workspaceTop: {
      display: 'flex',
      flex: 1,
    },

    // Workspace Middle Area
    workspaceMiddle: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      marginTop: theme.spacing(3),
    },

    mazeContainer: {
      display: 'flex',
      flex: 1,
    },

    // Middle Right Area
    mazeInfoArea: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: theme.spacing(1),
    },

    mazeInfoContainer: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },

    mazeOptionsContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 200,
      backgroundColor: 'lightblue',
    },

    mazeOptionContainer: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 200,
      // marginBottom: theme.spacing(1),
    },

    samplesContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 200,
      backgroundColor: 'lightgreen',
    },

    samplesInfoText: {
      fontWeight: 'bold',
    },

    mazeOptionName: {},

    mazeOptionValue: {
      fontWeight: 'bold',
    },

    // Bottom Area
    workspaceBottom: {
      display: 'flex',
      flex: 1,
      marginTop: theme.spacing(3),
    },

    // Arrows
    arrows: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      flexDirection: 'column',
    },

    sideArrows: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      width: 225,
    },

    arrowButton: {
      backgroundColor: 'lightblue',
    },

    arrowIcon: {
      height: 50,
      width: 50,
    },
  }),
  { name: 'WorkerLobby' }
)

const WorkerLobby = props => {
  const classes = useStyles(props)

  const {
    lobbyId,
    userId,
    mazeString,
    mazeLoading,
    prompt,
    numSamples,
    lockPellets,
    pelletSubsets,
    handleMazeStringChange,
  } = props

  const [actions, setActions] = useState([])
  const [numSamplesCompleted, setNumSamplesCompleted] = useState(0)
  const [done, setDone] = useState(false)
  const [tokenCounts, setTokenCounts] = useState({})
  const [pellets, setPellets] = useState([])

  // First load (count all tokens in maze)
  useEffect(() => {
    // Count tokens in the mazeString only the first time
    if (isEmpty(tokenCounts)) {
      const mazeArray = mazeStringToArray(mazeString)

      // Count all non-wall, non-space characters
      const counter = {}

      const newPellets = [...pellets]
      for (let i = 0; i < mazeArray.length; i++) {
        const row = mazeArray[i]
        for (let j = 0; j < row.length; j++) {
          const item = row[j]
          switch (item) {
            case WALL_TOKEN:
            case SPACE_TOKEN:
              break
            default:
              if (item === PELLET_TOKEN) {
                newPellets.push([j, i])
              }

              if (counter[item]) counter[item] += 1
              else counter[item] = 1
          }
        }
      }
      setPellets(newPellets)
      setTokenCounts({ ...counter })
    }
  }, [mazeString])

  // Actions Effect
  useEffect(() => {
    const updateUserLobby = async () => {
      try {
        if (userId && lobbyId) {
          const payload = {
            actions,
          }

          await patchUserLobby(userId, lobbyId, payload)
        }
      } catch (err) {
        console.error(err)
      }
    }
    updateUserLobby()
  }, [actions])

  // Samples Completed Effect
  useEffect(() => {
    if (numSamplesCompleted > 0 && numSamplesCompleted >= numSamples) setDone(true)
  }, [numSamplesCompleted])

  const randomizeMazePlayers = mazeString => {
    const array = mazeStringToArray(mazeString)
    const newArray = randomizeMazeArrayPlayers(array)
    const newString = mazeArrayToString(newArray)
    return newString
  }

  // Given a 2D array maze, randomize the placement of pacman, ghosts, and pellets
  const randomizeMazeArrayPlayers = mazeArray => {
    // dumpJson('TOKENS: ', tokens)
    // dumpGrid('PELLETS: ', pellets)

    // Count non-wall, non-space characters of the *latest* maze state
    const counter = {}
    for (let i = 0; i < mazeArray.length; i++) {
      const row = mazeArray[i]
      for (let j = 0; j < row.length; j++) {
        const item = row[j]
        switch (item) {
          case WALL_TOKEN:
          case SPACE_TOKEN:
            break
          default:
            if (lockPellets && item === PELLET_TOKEN) {
              // Lock Pellets Only: don't count the pellets nor remove them from the maze
              if (pelletSubsets) {
                // Unless Pellet Subsets is also enabled, then remove all the pellets first
                mazeArray[i][j] = SPACE_TOKEN
              }
            } else {
              if (counter[item]) counter[item] += 1
              else counter[item] = 1
              mazeArray[i][j] = SPACE_TOKEN
            }
        }
      }
    }

    // TODO: This logic is confusing; it would have been better to keep track of each pellet to begin with
    // TODO: Now that pellet locations are tracked, this logic can be rewritten
    if (!lockPellets && pelletSubsets) {
      // Pellet Subset ONLY: simply change the number of pellets to render
      counter[PELLET_TOKEN] = generateRandomInteger(1, tokenCounts[PELLET_TOKEN])
    } else if (lockPellets && pelletSubsets) {
      // Generate a random number of pellets to keep >= 1
      const randomNumPellets = generateRandomInteger(1, tokenCounts[PELLET_TOKEN])
      const shuffledPellets = shuffle(pellets)
      const slicedPellets = shuffledPellets.slice(0, randomNumPellets)

      // Add sliced pellets to the grid
      slicedPellets.forEach(item => {
        const x = item[0]
        const y = item[1]
        mazeArray[y][x] = PELLET_TOKEN
      })
    }

    const height = mazeArray.length
    const width = mazeArray[0].length

    const used = {}

    // Loop through all keys (pacman, ghosts, etc) in item counter
    const keys = Object.keys(counter)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]

      // If the counter is greater than zero, place the item on the maze
      while (counter[key] > 0) {
        // Place the key on a dot randomly looping as many times as needed
        let placed = false
        while (placed === false) {
          // Generate coordinates
          const y = generateRandomInteger(0, height)
          const x = generateRandomInteger(0, width)

          // Keep tracked of "used" coordinates to not overwrite previous placements
          const usedKey = `${y}-${x}`

          // Get the current item at the coordinates
          const current = mazeArray[y][x]
          // console.log('CURRENT: ' + current)

          // If it's a dot and the coordinates are not yet used...
          if (current === SPACE_TOKEN && !used[usedKey]) {
            // Replace the current maze item with the item key (randomly placed item)
            mazeArray[y][x] = key

            // Mark coordinates as used
            used[usedKey] = 1

            // Decrement the number of times we need to place this Player
            counter[key] -= 1

            // Mark this Player as placed to end the infinite loop
            placed = true
          }
        }
      }
    }

    // dumpJson("MAZE: ", mazeArray)
    // dumpGrid("MAZE:",mazeArray)

    return mazeArray
  }

  // Handlers
  const handleArrowClick = (event, direction) => {
    const newAction = {
      boardState: mazeString,
      action: direction,
    }

    const newActions = [...actions, newAction]
    setActions(newActions)

    const newMazeString = randomizeMazePlayers(mazeString)
    handleMazeStringChange(newMazeString)
    setNumSamplesCompleted(numSamplesCompleted + 1)
  }

  // dumpJson('TOKEN COUNTS: ', tokenCounts)

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Worker Lobby {SITE_TITLE_POSTFIX}</title>
      </Helmet>
      <div className={classes.workspace}>
        <div className={classes.workspaceTop}>
          <div className={classes.mazeContainer}>
            {mazeLoading ? <CircularProgress /> : <BlockMazeDisplay mazeString={mazeString} />}
          </div>
          <div className={classes.mazeInfoArea}>
            <div>
              <Paper className={clsx(classes.mazeInfoContainer, classes.mazeOptionsContainer)}>
                <div className={classes.mazeOptionContainer}>
                  <Typography className={classes.mazeOptionName}>Lock Pellets:&nbsp;</Typography>
                  <Typography className={classes.mazeOptionValue}>
                    {lockPellets ? 'ON' : 'OFF'}
                  </Typography>
                </div>
                <div className={classes.mazeOptionContainer}>
                  <Typography className={classes.mazeOptionName}>Pellet Subsets:&nbsp;</Typography>
                  <Typography className={classes.mazeOptionValue}>
                    {pelletSubsets ? 'ON' : 'OFF'}
                  </Typography>
                </div>
              </Paper>
              <Paper className={clsx(classes.mazeInfoContainer, classes.samplesContainer)}>
                <Typography className={classes.samplesInfoText}>Completed:</Typography>
                <Typography className={classes.samplesInfoText}>
                  {numSamplesCompleted} / {numSamples}
                </Typography>
              </Paper>
            </div>
          </div>
        </div>
        <div className={classes.workspaceMiddle}>
          <div className={classes.promptContainer}>
            <Typography>
              <strong>Prompt:</strong> {prompt}
            </Typography>
          </div>
        </div>
        <div className={classes.workspaceBottom}>
          <div className={classes.arrows}>
            <div className={classes.upArrow}>
              <IconButton
                className={classes.arrowButton}
                onClick={e => handleArrowClick(e, 'U')}
                disabled={done}
              >
                <KeyboardArrowUpIcon className={classes.arrowIcon} />
              </IconButton>
            </div>
            <div className={classes.sideArrows}>
              <IconButton
                className={classes.arrowButton}
                onClick={e => handleArrowClick(e, 'L')}
                disabled={done}
              >
                <KeyboardArrowLeftIcon className={classes.arrowIcon} />
              </IconButton>
              <IconButton
                className={classes.arrowButton}
                onClick={e => handleArrowClick(e, 'R')}
                disabled={done}
              >
                <KeyboardArrowRightIcon className={classes.arrowIcon} />
              </IconButton>
            </div>
            <div className={classes.downArrow}>
              <IconButton
                className={classes.arrowButton}
                onClick={e => handleArrowClick(e, 'D')}
                disabled={done}
              >
                <KeyboardArrowDownIcon className={classes.arrowIcon} />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

WorkerLobby.propTypes = {
  lobbyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  lobbyCode: PropTypes.string.isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userName: PropTypes.string.isRequired,
  numUsersInLobby: PropTypes.number.isRequired,
  refreshCount: PropTypes.number.isRequired,
  handleRefreshStatsClick: PropTypes.func.isRequired,
  mazeString: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  numSamples: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default WorkerLobby
