import { SPACE_TOKEN, WALL_TOKEN } from '../constants'

const dumpObject = obj => {
  let result = ''
  for (let i = 0; i < obj.length; i++) {
    const item = obj[i]

    if (typeof item === 'object') {
      result += JSON.stringify(item) + '\n'
    } else {
      result += item + SPACE_TOKEN
    }
  }
  return result
}

const generateInitialMaze = (width, height) => {
  const newMaze = []
  for (let row = 0; row < height; row++) {
    const newRow = []
    for (let col = 0; col < width; col++) {
      newRow.push(WALL_TOKEN)
    }
    newMaze.push(newRow)
  }
  return newMaze
}

// Given a mazeString, convert into a 2D array
const mazeStringToArray = mazeString => {
  const result = []
  const rowStrings = mazeString.trim().split('\n')

  rowStrings.forEach(string => {
    result.push(string.split(''))
  })
  return result
}

// Given a 2D array maze, convert into a mazeString
const mazeArrayToString = mazeArray => {
  const strings = []
  for (let i = 0; i < mazeArray.length; i++) {
    const row = mazeArray[i]
    const rowString = row.join('')
    strings.push(rowString)
  }
  return strings.join('\n')
}

export { dumpObject, generateInitialMaze, mazeArrayToString, mazeStringToArray }
